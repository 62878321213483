.burgerBtn {
  padding: 10px;
  color: black;
  border-radius: 5px;
}
.burgerBtn:hover {
  transition: 0.3s;
  background-color: #f2f2f2;
  color: black;
  border-radius: 5px;
}
