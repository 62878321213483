* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  background-color: #f1f1f1;
}
.navbarBtn {
  color: white !important;
}
.borderWho1 {
  border-left: solid 15px #1976d2;
}
.borderWho1:hover {
  border-left: solid 15px #146fca;
  transition: 0.7s;
  transform: scale(1.03);
}
.borderWho1:not(:hover) {
  transition: 0.7s;
}
.borderWho2 {
  border-right: solid 15px #1976d2;
}
.borderWho2:hover {
  border-right: solid 15px #146fca;
  transition: 0.7s;
  transform: scale(1.03);
}
.borderWho2:not(:hover) {
  transition: 0.7s;
}
